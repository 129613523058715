<template>
  <div>
    <b-card title="Dashboard Admin" v-if="checkPermission('dashboard statistic')">
      <p>Selamat Datang di Dashboard Admin</p>
      <!-- <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center justify-content-center" style="gap: 10px;">
          <filter-icon size="1.5x" class="custom-class"></filter-icon>
          <h3 class="m-0">Data Filter</h3>
        </div>
        <div class="d-flex" style="gap: 10px;">
          <button
            type="submit"
            variant="success"
            class="btn btn-sm waves-effect waves-float waves-light btn-success d-flex align-items-center"
          >
            <download-icon size="1.2x" class="custom-class"></download-icon>
            Export
          </button>
          <button
            type="submit"
            variant="success"
            class="btn btn-sm waves-effect waves-float waves-light btn-success d-flex align-items-center"
          >
            <download-icon size="1.2x" class="custom-class"></download-icon>
            Export Custom Report
          </button>
        </div>
      </div>
      <div class="p-2">
        <b-row class="my-1">
          <b-col cols="12" lg="3">
            <label for="input-default">Define Ranges:</label>
          </b-col>
          <b-col cols="12" lg="4" md="9">
            <b-form-input id="input-default"></b-form-input>
          </b-col>
        </b-row>
      </div> -->
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink, BFormInput, BRow, BCol } from 'bootstrap-vue'
import { FilterIcon, DownloadIcon } from 'vue-feather-icons'
import { checkPermission } from '@/auth/utils'
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BFormInput,
    BCol,
    BRow,
    FilterIcon,
    DownloadIcon
  },

  setup(props) {
		return {
			checkPermission,
		}
	},
}
</script>

<style>

</style>
